import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    activities: null,
    dashboardusers: null,
    alertcounts: null,
    mailtemplates: null,
    projectphases: null,
    hosters: null,
    services: null,
    users: []
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_activities: state => { return state.activities; },
    get_dashboardusers: state => { return state.dashboardusers; },
    get_alertcounts: state => { return state.alertcounts; },
    get_mailtemplates: state => { return state.mailtemplates; },
    get_projectphases: state => { return state.projectphases; },
    get_hosters: state => { return state.hosters; },
    get_services: state => { return state.services; },
    get_users: state => { return state.users; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_ACTIVITIES(state, val) { state.activities = val; },
    SET_DASHBOARDUSERS(state, val) { state.dashboardusers = val; },
    SET_ALERTCOUNTS(state, val) { state.alertcounts = val; },
    SET_MAILTEMPLATES(state, val) { state.mailtemplates = val; },
    SET_PROJECTPHASES(state, val) { state.projectphases = val; },
    SET_HOSTERS(state, val) { state.hosters = val; },
    SET_SERVICES(state, val) { state.services = val; },
    SET_USERS(state, val) { state.users = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_activities({ commit }, val) { commit('SET_ACTIVITIES', val); },
    set_dashboardusers({ commit }, val) { commit('SET_DASHBOARDUSERS', val); },
    set_alertcounts({ commit }, val) { commit('SET_ALERTCOUNTS', val); },
    set_mailtemplates({ commit }, val) { commit('SET_MAILTEMPLATES', val); },
    set_projectphases({ commit }, val) { commit('SET_PROJECTPHASES', val); },
    set_hosters({ commit }, val) { commit('SET_HOSTERS', val); },
    set_services({ commit }, val) { commit('SET_SERVICES', val); },
    set_users({ commit }, val) { commit('SET_USERS', val); }
  }
})